$primary: #ec671b;
$primary-accent: #d35c18;
$good: #68c58a;
$danger: #fa7a7a;
$light: #EBEBEB;
$grey: #cbcbcb;
$dark: #222222;
$white: #ffffff;

$background: #f4f4f4;
$line: #e8e8e8;

$text-medium: #696969;
$text-muted: #7f7f7f;
$text-dummy: #d8d8d8;

$btn-select: #f6f6f6;
$btn-plain: #d8d8d8;
$btn-medium: #c2c2c2;
$btn-dark: #4b4b4b;

$input-border: #bfbfbf;

$scrollbar-track: #e8e8e8;
$exercise-background: #f6f6f6;

$modal-min-height: 480px;
$modal-max-width: 940px;
$modal-v-spacing: 96px;

$light-text-normal-80: #333;
$light-text-normal-60: #666;

:root {
  --ion-color-primary: #ec671b;
  --exo-primary: #ec671b;
  --exo-bar-background: #fff;
  --exo-root-background: #f4f4f4;
  --exo-wrapper-background: #fff;
  --exo-wrapper-border: #e8e8e8;

  --exo-text-normal: #000;
  --exo-text-inverted: #000;
  --exo-text-soft: #222222;
  --exo-line-color: #e8e8e8;
  --exo-background-soft: #f5f5f5;
  --exo-background-hard: #ebebeb;
  --exo-background-plain: #fff;

  --exo-input-background: #ffffff;
  --exo-input-border: #e8e8e8;
  --exo-input-color: black;

  --exo-disabled-background: #ffffff;

  --exo-button-background: #dbdbdb;
  --exo-button-border: #cbcbcb;
  --exo-button-plain-bg: #e7e7e7;
  --exo-button-plain-color: #222222;
  --exo-button-light-bg: #ebebeb;

  --exo-text-light-fixed: #fff;
  --exo-text-dark-fixed: #000;
  --exo-text-blank-grade: #D1D1D1;
  --exo-text-less-grade: #ff7f7f;
  --exo-text-medium-grade: #8aceff;
  --exo-text-high-grade: #68c58a;

  --exo-text-subject-title: #333333;

  --card-line-color: #e8e8e8;
  --card-background-color: #fff;

  --button-plain-background-color: #eaeaea;

  --textNormal: #000000;
  --textNormal60: #666666;
  --textNormal80: #333333;
  --textDisabled: #a9a9a9;
  --textLight: #ffffff;
  --backgroundPlain: #ffffff;

  --progressBar: #e8e8e8;
  --progressBarFill: #68c58a;

  --exo-processing: #ec671b;
  --exo-success: #10dc60;
  --exo-failed: #f53d3d;

  --exo-cart-icon: #333333;

  --exo-high: #68C58A;
  --exo-mid: #EBC66B;
  --exo-low: #FF7F7F;
}

/* paddings general */
$padding-v: 16px;
$padding-h: 24px;
$padding-v-mobile: 16px;
$padding-h-mobile: 16px;

/* scroller */
$light: #e7e7e7;
$medium: #929292;

.scroller {
  height: inherit;
  overflow-y: scroll !important;

  /* width */
  &::-webkit-scrollbar {
    background: transparent;
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border: 4px solid transparent;
    background: $light;
    border-radius: 10px;
    background-clip: padding-box;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background: $medium;
    border-radius: 10px;
    background-clip: padding-box;
  }
}

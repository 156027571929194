@import "./bootstrap";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "variables";

@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,500i,600,700,800|Open+Sans);

@media print {
  @page {
    size: landscape; /* auto is the initial value */
    margin: 0; /* this affects the margin in the printer settings */
  }
}

body,
html {
  width: 100%;
  height: 100%;
  font-family: Montserrat, Roboto, "Helvetica Neue", Arial, sans-serif;
  transition: 3s transform;
  background-color: $background;
}

body {
  overflow-y: scroll;
}

* {
  outline: none;
}

a {
  &:hover {
    text-decoration: none;
  }
}

button {
  cursor: pointer;
  outline: 0;
}

.btn-link {
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}

input {
  box-shadow: inset 0 0 5px 0 transparentize($dark, 0.8);

  &:focus {
    box-shadow: inset 0 0 5px 0 transparentize($dark, 0.8) !important;
  }
}

.btn {
  cursor: pointer;
  color: $dark;
  background-color: $light;
  font-size: 15px;
  font-weight: 600;
  border-radius: 50px;
  padding: 6px 20px;

  &:not([disabled]):not(.disabled).active,
  &:not([disabled]):not(.disabled):active {
    background-color: darken($light, 10);
    box-shadow: none;
  }

  &:hover {
    background-color: darken($light, 5);
    color: $dark;
  }

  &.btn-primary {
    border: 1px solid $primary-accent;
    color: $light;
    background-color: $primary;

    &.btn-primary:not([disabled]):not(.disabled).active,
    &.btn-primary:not([disabled]):not(.disabled):active {
      background-color: darken($primary, 10);
      border: 1px solid darken($primary-accent, 10);
      box-shadow: none;
    }

    &:hover {
      border: 1px solid $primary;
      background-color: $primary-accent;
    }
  }

  &.btn-primary {
    border: 1px solid $primary-accent;
    color: $light;
    background-color: $primary;

    &.btn-primary:not([disabled]):not(.disabled).active,
    &.btn-primary:not([disabled]):not(.disabled):active {
      background-color: darken($primary, 10);
      border: 1px solid darken($primary-accent, 10);
      box-shadow: none;
    }

    &:hover {
      border: 1px solid $primary;
      background-color: $primary-accent;
    }
  }

  &.btn-cancel {
    background-color: #d0d0d0;

    &:hover {
      background-color: darken(#d0d0d0, 5);
    }
  }

  &:focus {
    box-shadow: none;
  }
}

/* spacings */
@for $i from 32 through 0 {
  .sl-#{$i} {
    margin-left: $i + px !important;
  }
}
@for $i from 32 through 0 {
  .sr-#{$i} {
    margin-right: $i + px !important;
  }
}
@for $i from 32 through 0 {
  .st-#{$i} {
    margin-top: $i + px !important;
  }
}
@for $i from 32 through 0 {
  .sb-#{$i} {
    margin-bottom: $i + px !important;
  }
}
@for $i from 32 through 0 {
  .sv-#{$i} {
    margin-top: $i + px !important;
    margin-bottom: $i + px !important;
  }
}

@for $i from 32 through 0 {
  .pl-#{$i} {
    padding-left: $i + px !important;
  }
}
@for $i from 32 through 0 {
  .pr-#{$i} {
    padding-right: $i + px !important;
  }
}
@for $i from 32 through 0 {
  .pt-#{$i} {
    padding-top: $i + px !important;
  }
}
@for $i from 32 through 0 {
  .pb-#{$i} {
    padding-bottom: $i + px !important;
  }
}
@for $i from 32 through 0 {
  .pv-#{$i} {
    padding-top: $i + px !important;
    padding-bottom: $i + px !important;
  }
}
@for $i from 32 through 0 {
  .ph-#{$i} {
    padding-left: $i + px !important;
    padding-right: $i + px !important;
  }
}

/* scroller */
.content-scroll {
  height: inherit;
  overflow-y: scroll;
  margin-right: 4px;

  /* width */
  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    margin-top: 4px;
    margin-bottom: 4px;
    background: transparent;
    border-radius: 2.5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 2.5px;
  }
}

.pointer {
  cursor: pointer;
}

.container {
  @media (min-width: 576px) {
    max-width: 100%;
  }
  @media (min-width: 768px) {
    max-width: 100%;
  }
  @media (min-width: 768px) {
    max-width: 100%;
  }
  @media (min-width: 992px) {
    max-width: 1120px;
  }
}

.widget {
  background-color: var(--card-background-color);
  border: 1px solid var(--card-line-color);
  margin: 24px 0 0;
  border-radius: 5px;
  font-size: 16px;
  position: relative;
  overflow: hidden;

  header,
  .header {
    padding: 20px 24px;
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: bold;
    display: flex;
    font-weight: bold;
    align-items: center;
    border-bottom: 1px solid var(--card-line-color);
    flex-wrap: wrap;

    p {
      margin-bottom: 0;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
    }

    .header-content-right {
      margin-left: auto;
    }

    .btn {
      margin-top: -1px;
      font-family: Montserrat;
      background-color: $light;
      font-size: 12px;
      border-radius: 50px;

      &:hover {
        background-color: darken($light, 5);
        color: $dark;
      }
    }
  }

  .content {
    padding: 20px 30px;
    width: 100%;
  }
}

.card {
  background: var(--card-background-color);
  border: 1px solid var(--card-line-color);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 24px;
  overflow: hidden;

  &.spaceless {
    padding: 0;
  }

  &.transparent {
    background-color: transparent;
    border-color: transparent;
  }

  header,
  .header {
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--card-line-color);

    font-family: "Montserrat", "Helvetica Neue", "sans-serif";
    font-size: 18px;
    font-weight: 700;
  }

  .content {
    padding: 24px;
  }

  @media (max-width: 1200px) {
    // TODO change res size
    // margin-bottom: 24px;
  }
}

.text {
  display: block;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-right: 12px;
  padding-left: 12px;
}

@import "texts";

h1,
h2,
h3 {
  margin: 0;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-split {
  display: flex;

  > * {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.input-plain {
  label {
    display: block;
    margin: 0;
  }
  input {
    border: none;
    border-radius: 0;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.input-regular {
  @extend .input-plain;
  margin-bottom: 14px;

  label {
    @extend .TextLabelRegular;
    padding-bottom: 4px;
  }

  input {
    @extend .TextInputRegular;
    border: solid 1px var(--exo-input-border);
    background-color: var(--exo-input-background);
    color: var(--exo-input-color);
    border-radius: 4px;
    padding: 24px 16px;
  }

  &.invalid {
    input {
      border-color: var(--exo-failed);
    }
  }
}

.form-error {
  display: block;
  color: var(--exo-failed);
}

.backdrop {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  z-index: 999;
  opacity: 1;
  background-color:rgba(0, 0, 0, 0.5);
}

.jax-process {
  svg {
    max-width: 100%;
  }
}

.modal-card {
  background-color: white;
  border-radius: 8px;
  position: relative;
  margin: 16px;
  min-width: 560px;

  @media(max-width: 768px) {
    padding: $padding-v $padding-h-mobile;
    min-width: auto;
  }

  .modal-card-header {
    position: relative;
    border-bottom: solid 1px $line;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media(max-width: 768px) {
      justify-content: flex-start;
      padding-left: 50px;
      padding-top: 0;
    }

    .title {
      font-size: 18px;
      font-weight: 700;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 500;
    }

    .stick-left, .stick-right {
      position: absolute;
      left: $padding-h-mobile;
      top: 50%;
      transform: translate(0, -50%);
    }

    .stick-right {
      left: auto;
      right: 32px;
    }

    .modal-card-close {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .modal-card-footer {
    position: relative;
    border-top: solid 1px $line;
    text-align: right;
    display: flex;
    justify-content: end;
  }

  .modal-card-body {
    &.splitter {
      display: flex;
      height: 100%;
    
      .split {
        width: 50%;
        border-right: solid 1px var(--exo-line-color);
      
        &:last-of-type {
          border-right: 0;
        }
        overflow-y: scroll;
      };
    
      @media(max-width: 768px) {
        display: block;
        
        .split {
          width: 100%;
          overflow-y: auto;
    
          &:first-of-type {
            border-right: 0;
          }
    
          &:last-of-type {
            border-right: 0;
            border-top: solid 1px var(--exo-line-color);
          }
        };
      }
    }
  }

  // paddings and margins
  .modal-card-header, .modal-card-footer, .split {
    padding: $padding-v $padding-h;

    @media(max-width: 768px) {
      padding: $padding-v-mobile $padding-h-mobile;
    }
  }

}

.TextSubjectChapter {
  font-family: "Montserrat", "sans-serif";
  font-size: 16px;
  font-weight: 700;
}
.TextSubjectSectionAction {
  font-family: "Montserrat", "sans-serif";
  font-size: 15px;
  font-weight: 700;
}
.TextSubjectSectionScore {
  font-family: "Montserrat", "sans-serif";
  font-size: 23px;
  font-weight: 700;
}

.TextSubjectSummaryTitle {
  font-family: "Montserrat", "sans-serif";
  font-size: 16px;
  font-weight: 700;
}

.TextSubjectMenuItem {
  font-family: "Inter", "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

.TextSubjectTitle {
  font-family: "Montserrat", "sans-serif";
  font-size: 24px;
  font-weight: 600;
}

.TextSubjectSubtitle {
  font-family: "Open Sans", "sans-serif";
  font-size: 16px;
  font-weight: 400;
}

.TextSubjectIndexChapter {
  font-family: "Montserrat", "sans-serif";
  font-weight: 700;
  font-size: 16px;
}

.TextSubjectIndexItem {
  font-family: "Open Sans", "sans-serif";
  font-weight: 500;
  font-size: 17px;
}

.TextSubjectIndexItemSummary {
  font-family: "Open Sans", "sans-serif";
  font-weight: 400;
  font-size: 15px;
  &.active {
    font-weight: 600;
  }
}

.TextProgressText {
  font-family: "Montserrat", "sans-serif";
  font-weight: 600;
  font-size: 18px;
  color: var(--textNormal60);
}

.TextContinueLearning {
  font-family: "Montserrat", "sans-serif";
  font-weight: 700;
  font-size: 16px;
  color: var(--backgroundPlain);
}

.TextContinueLearning {
  font-family: "Montserrat", "sans-serif";
  font-weight: 700;
  font-size: 16px;
  color: var(--backgroundPlain);
}
.TextContinueLearningSub {
  font-family: "Montserrat", "sans-serif";
  font-weight: 500;
  font-size: 15px;
  color: var(--backgroundPlain);
}

.TextSubjectBadgeTitle {
  font-family: "Montserrat", "sans-serif";
  font-weight: 600;
  font-size: 16px;
}

.TextSubjectBadgeSubtext {
  font-family: "Open Sans", "sans-serif";
  font-size: 14px;
  font-weight: 400;
}

.TextSubjectRequiredScore {
  font-family: "Montserrat", "sans-serif";
  font-weight: 600;
  font-size: 16px;
  color: var(--textNormal60);
}

.TextSubjectRequiredScoreGrade {
  font-family: "Montserrat", "sans-serif";
  font-weight: 600;
  font-size: 17px;
}

.TextSubjectCheckoutTitle {
  font-family: "Montserrat", "sans-serif";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.8px;
  color: var(--textNormal);
}

.TextSubjectCheckoutSubject {
  font-family: "Open Sans", "sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: var(--textNormal60);
}

.TextButtonPrimary {
  font-family: "Montserrat", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: -0.4px;
}

.TextTitleRegular {
  font-family: "Montserrat", "sans-serif";
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.145px;
}

.TextCartPrivacy {
  font-family: "Open Sans", "sans-serif";
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  display: block;
}

.TextLabelRegular {
  font-family: "Montserrat", "sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.TextInputRegular {
  font-family: "Montserrat", "sans-serif";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.TextDiscountDescription {
  font-family: "Montserrat", "sans-serif";
  font-size: 14px;
  line-height: 17px;
  color: var(--textNormal60);
}

.TextDiscountTotal {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.TextSummaryResultsTitle {
  font-family: "Montserrat", "sans-serif";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  display: block;
}

.TextSummaryResultsSubTitle {
  font-family: "Montserrat", "sans-serif";
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  display: block;
}

.TextPaymentsOptionsName {
  font-family: "Montserrat", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.15px;
}

.TextPaymentsOptionsIssuers {
  font-family: "Montserrat", "sans-serif";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.15px;
}

.TextPaymentTitle {
  font-family: "Montserrat", "sans-serif";
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.15px;
}

.TextPaymentTitleMedium {
  font-family: "Montserrat", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.15px;
}

.TextSummaryTopicsTitle {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.056px;
}

.PopupTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
